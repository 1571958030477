.empty {
  margin-top: 20px;
}

.actionButtons {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  :global {
    .ant-table-cell {
      padding: 8px 4px !important;
    }
  }

  .table {
    :global {
      .ant-table-expand-icon-col {
        width: 30px;
      }

      .ant-table-cell {
        font-size: 12px;
        padding: 0 4px;

      }

      .ant-table {
        padding: 8px 4px !important;
      }
    }
  }

  .reofferButton {
    font-size: 12px;
    width: 75px;
    height: auto;
    white-space: normal;
  }

  .openButton {
    display: none;
  }

  .actionButtons {
    flex-direction: column;

    :global {
      .ant-alert {
        font-size: 10px;
      }
    }
  }
}
