.chosenRow {
  background: #efb041;

  &:hover > td {
    background: #efb041 !important;
  }
}

.selectedServicesTable {
  table {
    :global {
      .ant-table-cell {
        color: #7cb305;
      }
    }
  }
}
