.chosenPatient {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h4 {
    margin-bottom: 0;
  }
}

@media (max-width: 500px) {
  .chosenPatient {
    font-size: 12px;
  }
}
