.studyFile {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .3);
  z-index: 10;
}

.close {
  position: fixed;
  top: 13px;
  right: 15px;
  background: #ffffff;
  border: none;
  font-size: 20px;
  border-radius: 5px;
  z-index: 15;
  cursor: pointer;
}

.errorBlock {
  width: 70%;
  border-radius: 20px;
  background: rgba(255, 255, 255, .9);
}

.pdf {
  width: 100%;
  height: 100%;
}
