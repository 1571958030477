.editTableButton {
  position: absolute;
  z-index: 5;
  left: -5px;
  top: -20px;
}

.patientForm {
  :global {
    .ant-form-item {
      margin-bottom: 5px;
    }
  }
}

.chosenRow {
  background: #efb041;

  &:hover > td {
    background: #efb041 !important;
  }
}

.errorMessage {
  margin-top: 24px;
}

